import { sampleSize, shuffle } from "lodash";

export const fullData = {
  happy: [
    {
      sequence: 128525,
      negative: 0.05187048098082364,
      neutral: 0.21864193649795663,
      positive: 0.7294875825212197,
      overall: 0.677617101540396,
      category: "happy",
    },
    {
      sequence: 128536,
      negative: 0.05313612708846891,
      neutral: 0.19254998630512188,
      positive: 0.7543138866064092,
      overall: 0.7011777595179403,
      category: "happy",
    },
    {
      sequence: 128522,
      negative: 0.05957980558168705,
      neutral: 0.2367513327061775,
      positive: 0.7036688617121355,
      overall: 0.6440890561304484,
      category: "happy",
    },
    {
      sequence: 128170,
      negative: 0.07223796033994334,
      neutral: 0.3009915014164306,
      positive: 0.626770538243626,
      overall: 0.5545325779036827,
      category: "happy",
    },
    {
      sequence: 128150,
      negative: 0.04344391785150079,
      neutral: 0.2014218009478673,
      positive: 0.7551342812006319,
      overall: 0.711690363349131,
      category: "happy",
    },
    {
      sequence: 127881,
      negative: 0.03900709219858156,
      neutral: 0.18439716312056736,
      positive: 0.776595744680851,
      overall: 0.7375886524822695,
      category: "happy",
    },
    {
      sequence: 128526,
      negative: 0.10568031704095113,
      neutral: 0.29722589167767505,
      positive: 0.5970937912813739,
      overall: 0.4914134742404227,
      category: "happy",
    },
    {
      sequence: 128139,
      negative: 0.03934871099050204,
      neutral: 0.23066485753052918,
      positive: 0.7299864314789688,
      overall: 0.6906377204884667,
      category: "happy",
    },
    {
      sequence: 128523,
      negative: 0.04613297150610583,
      neutral: 0.276797829036635,
      positive: 0.6770691994572592,
      overall: 0.6309362279511533,
      category: "happy",
    },
    {
      sequence: 128293,
      negative: 0.12385321100917432,
      neutral: 0.6131498470948012,
      positive: 0.26299694189602446,
      overall: 0.13914373088685014,
      category: "happy",
    },
    {
      sequence: 127882,
      negative: 0.05737704918032787,
      neutral: 0.16393442622950818,
      positive: 0.7786885245901639,
      overall: 0.7213114754098361,
      category: "happy",
    },
    {
      sequence: 128081,
      negative: 0.04318936877076412,
      neutral: 0.21926910299003322,
      positive: 0.7375415282392026,
      overall: 0.6943521594684385,
      category: "happy",
    },
    {
      sequence: 127874,
      negative: 0.08333333333333333,
      neutral: 0.22058823529411764,
      positive: 0.696078431372549,
      overall: 0.6127450980392156,
      category: "happy",
    },
    {
      sequence: 127942,
      negative: 0.03553299492385787,
      neutral: 0.20304568527918782,
      positive: 0.7614213197969543,
      overall: 0.7258883248730964,
      category: "happy",
    },
    {
      sequence: 127796,
      negative: 0.07734806629834254,
      neutral: 0.32044198895027626,
      positive: 0.6022099447513812,
      overall: 0.5248618784530387,
      category: "happy",
    },
    {
      sequence: 127801,
      negative: 0.022857142857142857,
      neutral: 0.35428571428571426,
      positive: 0.6228571428571429,
      overall: 0.6,
      category: "happy",
    },
    {
      sequence: 127867,
      negative: 0.10714285714285714,
      neutral: 0.27380952380952384,
      positive: 0.6190476190476191,
      overall: 0.511904761904762,
      category: "happy",
    },
    {
      sequence: 127774,
      negative: 0.024242424242424242,
      neutral: 0.3939393939393939,
      positive: 0.5818181818181818,
      overall: 0.5575757575757575,
      category: "happy",
    },
    {
      sequence: 11088,
      negative: 0.037037037037037035,
      neutral: 0.345679012345679,
      positive: 0.6172839506172839,
      overall: 0.5802469135802468,
      category: "happy",
    },
    {
      sequence: 128529,
      negative: 0.015625,
      neutral: 0.453125,
      positive: 0.53125,
      overall: 0.515625,
      category: "happy",
    },
    {
      sequence: 128141,
      negative: 0.14782608695652175,
      neutral: 0.22608695652173913,
      positive: 0.6260869565217392,
      overall: 0.4782608695652174,
      category: "happy",
    },
    {
      sequence: 127752,
      negative: 0.06593406593406594,
      neutral: 0.3516483516483517,
      positive: 0.5824175824175825,
      overall: 0.5164835164835165,
      category: "happy",
    },
    {
      sequence: 128145,
      negative: 0.07058823529411765,
      neutral: 0.2,
      positive: 0.7294117647058823,
      overall: 0.6588235294117647,
      category: "happy",
    },
  ],
  negative: [
    {
      sequence: 128530,
      negative: 0.590778097982709,
      neutral: 0.19236311239193082,
      positive: 0.21685878962536023,
      overall: -0.37391930835734877,
      category: "negative",
    },

    {
      sequence: 128545,
      negative: 0.5322793148880105,
      neutral: 0.1080368906455863,
      positive: 0.35968379446640314,
      overall: -0.17259552042160736,
      category: "negative",
    },

    {
      sequence: 128148,
      negative: 0.41389728096676737,
      neutral: 0.2930513595166163,
      positive: 0.2930513595166163,
      overall: -0.12084592145015105,
      category: "negative",
    },

    {
      sequence: 128550,
      negative: 0.544,
      neutral: 0.281,
      positive: 0.175,
      overall: -0.368,
      category: "negative",
    },

    {
      sequence: 128546,
      negative: 0.513,
      neutral: 0.285,
      positive: 0.2024,
      overall: -0.311,
      category: "negative",
    },
    {
      sequence: 128566,
      negative: 0.425,
      neutral: 0.2916666666666667,
      positive: 0.2833333333333333,
      overall: -0.14166666666666666,
      category: "negative",
    },

    {
      sequence: 128532,
      negative: 0.6575342465753424,
      neutral: 0.2191780821917808,
      positive: 0.1232876712328767,
      overall: -0.5342465753424657,
      category: "negative",
    },
  ],
  slow: [
    {
      sequence: 128564,
      negative: 0.42163661581137307,
      neutral: 0.23717059639389737,
      positive: 0.34119278779472956,
      overall: -0.08044382801664351,
      category: "slow",
    },
    {
      sequence: 128012,
      negative: 0.058823529411764705,
      neutral: 0.23529411764705882,
      positive: 0.7058823529411765,
      overall: 0.6470588235294118,
      category: "slow",
    },
  ],
  fast: [
    {
      sequence: 128131,
      negative: 0.044543429844097995,
      neutral: 0.17668893838158872,
      positive: 0.7787676317743133,
      overall: 0.7342242019302153,
      category: "fast",
    },
    {
      sequence: 127754,
      negative: 0.09183673469387756,
      neutral: 0.3163265306122449,
      positive: 0.5918367346938775,
      overall: 0.5,
      category: "fast",
    },
    {
      sequence: 128048,
      negative: 0.08620689655172414,
      neutral: 0.2413793103448276,
      positive: 0.6724137931034483,
      overall: 0.5862068965517242,
      category: "fast",
    },
    {
      sequence: 128640,
      negative: 0.06976744186046512,
      neutral: 0.37209302325581395,
      positive: 0.5581395348837209,
      overall: 0.4883720930232558,
      category: "fast",
    },
    {
      sequence: 127940,
      negative: 0.07142857142857142,
      neutral: 0.3333333333333333,
      positive: 0.5952380952380952,
      overall: 0.5238095238095238,
      category: "fast",
    },
    {
      sequence: 128029,
      negative: 0.15789473684210525,
      neutral: 0.47368421052631576,
      positive: 0.3684210526315789,
      overall: 0.21052631578947367,
      category: "fast",
    },
    {
      sequence: 128014,
      negative: 0.12,
      neutral: 0.36,
      positive: 0.52,
      overall: 0.4,
      category: "fast",
    },
    {
      sequence: 127906,
      negative: 0.11764705882352941,
      neutral: 0.29411764705882354,
      positive: 0.5882352941176471,
      overall: 0.47058823529411764,
      category: "fast",
    },
    {
      sequence: 128642,
      negative: 0.0625,
      neutral: 0.625,
      positive: 0.3125,
      overall: 0.25,
      category: "fast",
    },
    {
      sequence: 128000,
      negative: 0.14285714285714285,
      neutral: 0.5714285714285714,
      positive: 0.2857142857142857,
      overall: 0.14285714285714285,
      category: "fast",
    },
    {
      sequence: 127938,
      negative: 0.15384615384615385,
      neutral: 0.3076923076923077,
      positive: 0.5384615384615384,
      overall: 0.3846153846153846,
      category: "fast",
    },
    {
      sequence: 127904,
      negative: 0.125,
      neutral: 0.375,
      positive: 0.5,
      overall: 0.375,
      category: "fast",
    },
  ],
  instrumental: [
    {
      sequence: 127932,
      negative: 0.057692307692307696,
      neutral: 0.5576923076923077,
      positive: 0.38461538461538464,
      overall: 0.3269230769230769,
      category: "instrumental",
    },

    {
      sequence: 127927,
      negative: 0.058823529411764705,
      neutral: 0.23529411764705882,
      positive: 0.7058823529411765,
      overall: 0.6470588235294118,
      category: "instrumental",
    },

    {
      sequence: 127931,
      negative: 0.08333333333333333,
      neutral: 0.5,
      positive: 0.4166666666666667,
      overall: 0.33333333333333337,
      category: "instrumental",
    },
    {
      sequence: 127930,
      negative: 0.16666666666666666,
      neutral: 0.08333333333333333,
      positive: 0.75,
      overall: 0.5833333333333334,
      category: "instrumental",
    },
  ],
};

export const randomize = () => {
  const happyList = fullData["happy"];
  const negativeList = fullData["negative"];
  const slowList = fullData["slow"];
  const fastList = fullData["fast"];
  const instrumentalList = fullData["instrumental"];

  const happyRandom = sampleSize(happyList, 10);
  const negativeRandom = sampleSize(negativeList, 7);
  const slowRandom = sampleSize(slowList, 2);
  const fastRandom = sampleSize(fastList, 6);
  const instRandom = sampleSize(instrumentalList, 3);

  const newEmoji = [
    ...happyRandom,
    ...negativeRandom,
    ...slowRandom,
    ...fastRandom,
    ...instRandom,
  ];

  return shuffle(newEmoji);
};
